/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const root = window //typeof exports !== 'undefined' && exports !== null ? exports : this;

// wrapper on ajax success function
root.ajaxCbWrapper = function(data, your_func) {
  const token = data.getResponseHeader("ajax-redirect");
  if ((token != null) && (token !== '0')) {
    return window.location.replace(token);  // redirection to login
  } else {
    return your_func(data);
  }
};

root.sessionExpired = function(data) {
  const token = data.getResponseHeader("ajax-redirect");
  if ((token != null) && (token !== '0')) {
    window.location.replace(token); // redirection
    return true;
  } else {
    return false;
  }
};

/*
  opts:{
    method: 'POST'/'GET'
    success:
    error:

*/
root.loadPartial = function(el, url, opts) {
  opts = opts != null ? opts : {};
  return $.ajax({
    url,
    type: opts.method != null ? opts.method : 'GET',
    "success"(data, status, xhr) {
      if (!sessionExpired(xhr)) {
        $(el).empty().append(data);
        if (supportNativePlaceholder) {
          $('form', el).toggleClass('form-horizontal', false);
          if (!support_float_label) { $('label.control-label', el).remove(); }
        }
        if (support_float_label) { initFloatLabels(); }
        if (opts.success != null) { return opts.success(data); }
      }
    }
    ,
    "error"(xhr, status, errors) {
      if (opts.error != null) { return opts.error(xhr); }
    }
  });
};

const get_model_from_class = function(cc) {
  for (let c of Array.from(cc.split(" "))) {
    if (c.match('^btn-new') || c.match('^btn-edit') || c.match('^btn-delete')) {
      return c.split('-').slice(2).join('-');
    }
  }
  return '';
};

const get_modal_body = (model, action) => `#modal-body-${action}-${model}`;

const show_modal = function(model, action, opts) {
  opts = opts != null ? opts : {};
  opts['dynamic'] = true;
  const modal = $(get_modal_body(model, action)).closest('.modal');
  modal.modal(opts);
  if ((model === 'service-grade') && ((action === 'new') || (action === 'edit'))) {
    init_company_selection(action);
  }
  if ((model === 'app-integration') && ((action === 'new'))) {
    init_company_selection_ai(action);
  }
  if ((model === 'report') && ((action === 'new') || (action === 'edit'))) {
    init_panel_selection(action);
  }
  if ((model === 'company') && ((action === 'new') || (action === 'edit'))) {
    init_country_selection(action);
  }
  return focus_modal_elem(modal);
};

var init_company_selection = function(action) {
  const defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 0,
    includeSelectAllOption: false,
    nonSelectedText: I18n.t('no_company_selected'),
    nSelectedText: I18n.t('companies_selected'),
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: I18n.t('search')
  };
  return $(`#modal-body-${action}-service-grade #service_grade_company_specific_grade_company_id`).multiselect(defaultSelectOptions);
};

var init_company_selection_ai = function(action) {
  // Add any model box level logic
};


var init_panel_selection = function(action) {
  const defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 0,
    includeSelectAllOption: false,
    nonSelectedText: I18n.t('no_device_selected'),
    nSelectedText: I18n.t('devices_selected'),
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: I18n.t('search')
  };
  return $(`#modal-body-${action}-report #report_filter`).multiselect(defaultSelectOptions);
};

var init_country_selection = function(action) {
  const defaultSelectOptions = {
    maxHeight: 200,
    numberDisplayed: 0,
    includeSelectAllOption: false,
    nonSelectedText: I18n.t('no_country_selected'),
    nSelectedText: I18n.t('countries_selected'),
    enableCaseInsensitiveFiltering: true,
    filterPlaceholder: I18n.t('search')
  };
  return $(`#modal-body-${action}-company #company_regional_distributor_link_country_code`).multiselect(defaultSelectOptions);
};

root.focus_modal_elem = modal => // TODO: update "shown" to "shown.bs.modal" on BS3 upgrade
  modal.on('shown', function(e) {
    let elem = $(this).find("form .form-group :visible:enabled:first");
    if (elem.length > 0) {
      elem.trigger('blur').trigger('focus');
    } else {
      modal.trigger('focus');
    }
    return $('.modal-backdrop').off('click').on('click', function(e) {
      if (e.target === this) {
        elem = $(this).find("form .form-group :visible:enabled:first");
        if (elem.length > 0) {
          return elem.trigger('focus');
        } else {
          return modal.trigger('focus');
        }
      }
    });
  });

const setup_buttons = function(elem,model,action, opts) {
  const modal = $(get_modal_body(model, action)).closest('.modal');
  const buttons = $(elem).attr('data-buttons') || "";
  const buttons_ignore  = $(elem).attr('data-buttons-ignore') || "";
  if(buttons !== "") {
    let btn;
    if (opts) { opts = opts['buttons'] || []; }
    modal.find('.modal-footer').html('');
    let val = "";
    const object = buttons.split(',');
    for (let k in object) {
      val = object[k];
      let css = "btn-primary";
      if (opts && opts[k]) { css = opts[k]['class']; }
      btn = $("<a/>").attr('class','btn ' + css + ' ' + action + '-object-submit btn-'+model+'-'+css).html(val).
            attr('data-value',val);
      if (jQuery.inArray(val, buttons_ignore.split(",")) > -1) {
        btn.attr('ignore-validation', true);
      }
      if (val !== "") {
        modal.find('.modal-footer').append(btn);
      }
    }
    btn = $("<a/>").attr('class','btn btn-default').attr('data-dismiss','modal').html('Close');
    modal.find('.modal-footer').append(btn);
    return submit_buttons_init();
  }
};

var submit_buttons_init = function(table_name) {

  if (table_name == null) { table_name = ""; }
  return $(`${table_name} .new-object-submit, .edit-object-submit, .delete-object-submit`).off('click').on('click', function() {
    if($(this).attr('ignore-validation') === "true") { $(this).closest('.modal').find('form').disableClientSideValidations(); }
    return $(this).closest('.modal').find('form').addHidden('commit', $(this).attr('data-value')).submit();
  });
};

/*
enable validation on modaled form
*/
root.initValidationOnModal = () => $('.modal').on('shown', function() {
  // init client side validation for new and edit form
  return $(this).find('form[data-client-side-validations]').validate();
});

/*
enable placeholder on modaled form
*/
root.initPlaceHolderOnModal = () => $('.modal').on('shown', function() {
  // init placeholder for form input
  return $(this).initPlaceHolder();
});

jQuery.fn.extend({
  clientside_modal(opts) {
    let body = '<div class="modal-header"><h3>' + opts['title'] + '</h3></div>';
    body += '<div class="modal-body">' + opts['body'] + '</div>';
    body += '<div class="modal-footer">' + opts['footer'] + '</div>';
    return $(this).empty().append(body).modal({ dynamic: true });
  }
});
const modal_placeholder_init = function(enable_validation) {
  if ($('#modal_box').length === 0) {
    $('body').append('<div class="modal fade" id="modal_box"></div>');
    if (enable_validation) {
      $('#modal_box').on('shown', function() {
        loginfo('modal show');
        return $('form', this).enableClientSideValidations();
      });
    }
  } else {
    $('#modal_box').empty();
  }

  return $('#modal_box');
};

/*
opts will pass some init function for particular model
opts = {
  modal_name: {
    new: () -> ,
    edit: () -> ,
    delete: () ->
  }
  ,
  ...
}
*/
root.init_object_crud = function(opts) {
  opts = opts != null ? opts : {};
  const modal = modal_placeholder_init(opts['enable_validation']);
  const target_table_name = opts['target-table'] === undefined ? "" : opts['target-table'];

  $(`${target_table_name} .new-object`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body(model, 'new'), url, {
      success() {
        if ((opts[model] != null) && (opts[model]['new'] != null)) { opts[model]['new'](self, 'new'); }
        return show_modal(model, 'new');
      }
    });
    // return false to prevent default link
    return false;
  });

  $(`${target_table_name} .edit-object`).off('click').on('click', function() {
    const self = this;
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body(model, 'edit'), url, {
      success() {
        if ((opts[model] != null) && (opts[model]['edit'] != null)) { opts[model]['edit'](self, 'edit'); }
        setup_buttons(self,model,'edit', opts[model]);
        return show_modal(model, 'edit');
      }
    });
    // return false to prevent default link
    return false;
  });

  $(".edit-object-submit-suspend-sim").off('click').on('click', function() {
    $("#change-sim-state-form").submit();
    return true;
  });

  $(".edit-object-submit-app-integration").off('click').on('click', function() {
    $("#change-app-state-form").submit();
    return true;
  });

  $(".edit-object-submit-resume-sim").off('click').on('click', function() {
    $("#change-sim-state-form").submit();
    return true;
  });

  $(`${target_table_name} .delete-object`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    $(get_modal_body(model, 'delete')).find('form').attr('action', url);
    if ((opts[model] != null) && (opts[model]['delete'] != null)) { opts[model]['delete']($(this)); }
    show_modal(model, 'delete');
    // return false to prevent default link
    return false;
  });

  submit_buttons_init(target_table_name);

  $(`${target_table_name} .btn-view-dialog`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.view-dialog .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        approval_pagination();
        $(".view-dialog").modal({ dynamic: true });
        return focus_modal_elem($(".view-dialog"));
      }
    });
    if (title) { $('.view-dialog .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .dialog-reactive-app`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.view-dialog .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        approval_pagination();
        $(".view-dialog").modal({ dynamic: true });
        return focus_modal_elem($(".view-dialog"));
      }
    });
    if (title) { $('.view-dialog .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-support-contact`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.support-contact .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".support-contact").modal({ dynamic: true });
        return focus_modal_elem($(".support-contact"));
      }
    });
    if (title) { $('.support-contact .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-suspend-sim`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    const account_type = $("#sierra_account_type").val();
    $('.suspend-sim .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".suspend-sim").modal({ dynamic: true });
        $("#account_type").val(account_type);
        return focus_modal_elem($(".suspend-sim"));
      }
    });
    if (title) { $('.suspend-sim .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-app-state-change`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.app-state-change .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".app-state-change").modal({ dynamic: true });
        return focus_modal_elem($(".app-state-change"));
      }
    });
    if (title) { $('.app-state-change .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-resume-sim`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    const account_type = $("#sierra_account_type").val();
    $('.resume-sim .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".resume-sim").modal({ dynamic: true });
        $("#account_type").val(account_type);
        return focus_modal_elem($(".resume-sim"));
      }
    });
    if (title) { $('.resume-sim .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-contract-history`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.contract-history .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".contract-history").modal({ dynamic: true });
        return focus_modal_elem($(".contract-history"));
      }
    });
    if (title) { $('.btn-contract-history .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-transfer-accounts`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-original-title');
    $('.transfer-accounts .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".transfer-accounts").modal({ dynamic: true });
        return focus_modal_elem($(".transfer-accounts"));
      }
    });
    if (title) { $('.transfer-accounts .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-special-instructions`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-otiginal-title');
    $('.special-instructions .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".special-instructions").modal({ dynamic: true });
        return focus_modal_elem($(".special-instructions"));
      }
    });
    if (title) { $('.special-instructions .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-document-upload`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-otiginal-title');
    $('.document-upload .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".document-upload").modal({ dynamic: true });
        return focus_modal_elem($(".document-upload"));
      }
    });
    if (title) { $('.document-upload .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-onboarding-customer`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-otiginal-title');
    $('.onboarding-customer .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".fa.fa-search-plus").trigger("click");
        initToolTip();
        country_select_init("onboarding_customer", "#onboarding_customer_country_code" );
        $("#onboarding_customer_country_code").trigger('change');
        $(".onboarding-customer").modal({ dynamic: true });
        return focus_modal_elem($(".onboarding-customer"));
      }
    });
    if (title) { $('.onboarding-customer .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .view-onboarding-request`).off('click').on('click', function() {
    const url = $(this).attr('url');
    const title = $(this).attr('data-otiginal-title');
    $('.onboarding-customer-edit .modal-body').html(
      '<div class="spinner"></div>'
    ).load(url, function(a,b,c) {
      if (!sessionExpired(c)) {
        $(".fa.fa-search-plus").trigger("click");
        country_select_init("onboarding_customer", "#onboarding_customer_country_code" );
        $("#onboarding_customer_country_code").trigger('change');
        $(".onboarding-customer-edit").modal({ dynamic: true });
        return focus_modal_elem($(".onboarding-customer-edit"));
      }
    });
    if (title) { $('.onboarding-customer-edit .modal-title').html(title); }
    return false;
  });

  $(`${target_table_name} .btn-new-recorder-firmware-upgrade`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body(model, 'new'), url, {
      success() {
        $(".recorder-firmware-upgrade-all").modal({ dynamic: true });
        const defaultSelectOptions = {
          maxHeight: 200,
          numberDisplayed: 0,
          nonSelectedText: I18n.t("none_selected"),
          nSelectedText: I18n.t('devices_selected'),
          enableCaseInsensitiveFiltering: true,
          includeSelectAllOption: true,
          buttonTitle: function() {}
        };
        $(`#recorder_response_filter`).multiselect(defaultSelectOptions);

        $("input:checkbox[name='recorder_response[recorder_model][]']").on("change", function (e) {
          $(".recorder_response_filter input").prop("disabled", true);
          $.ajax({
            url: "/reports/filter_recorders",
            data: {
              request_type: "fw_upgrade_notification",
              recorder_model: $("input:checkbox[name='recorder_response[recorder_model][]']:checked").map(
                function () { return this.value; }).get()
            },
            type: "GET",
            dataType: "JSON",
            success: function (data, status, xhr) {
              if (!sessionExpired(xhr)) {
               if ($("#selected_recorder_sids").val() !== 'undefined') {
                $("#recorder_model_options #recorder_response_filter").multiselect('dataprovider', data.recorders);
               } else {
                preselected_values = JSON.parse($("#selected_recorder_sids").val());
                $("#recorder_model_options #recorder_response_filter").multiselect('dataprovider', data.recorders);
                $("#recorder_model_options #recorder_response_filter").multiselect('select', preselected_values);
               }
              }
              $(".recorder_response_filter input").prop("disabled", false);
            },
            error: function (xhr, status, errors) {
              console.log("ajax error block");
              $(".recorder_response_filter input").prop("disabled", false);
            }
          });
        });
        return focus_modal_elem($(".recorder-firmware-upgrade-all"));
      }
    });
    return false;
  });

  $(`${target_table_name} .btn-new-firmware-upgrade`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body(model, 'new'), url, {
      success() {
        $("#download_job_product_type").val("all").trigger('change');
        const defaultSelectOptions = {
          maxHeight: 800,
          numberDisplayed: 0,
          includeSelectAllOption: true,
          nonSelectedText: I18n.t('select_dealer'),
          allSelectedText: I18n.t('all_selected')
        };
        $('#download_job_branch_id').multiselect(defaultSelectOptions);
        const batch_time = $("#batch_firmware_upgrade_time").val();
        $('#datetimepicker').datetimepicker({
          format:'YYYY-MM-DD HH:mm:ss',
          ignoreReadonly: true,
          minDate: batch_time
         // startDate: new Date($('#datetimepicker').attr('data-range-start'))
        });
        $(".firmware-upgrade-all").modal({ dynamic: true });
        return focus_modal_elem($(".firmware-upgrade-all"));
      }
    });
    return false;
  });

  $(`${target_table_name} .btn-modify-routing-option`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body('report-channel', 'update_route'), url, {
      success() {
        $(".modify-routing-option").modal({ dynamic: true });
        return focus_modal_elem($(".modify-routing-option"));
      }
    });
    return false;
  });

  $(`${target_table_name} .toggle-vfnn-object`).off('click').on('click', function() {
    const model = get_model_from_class($(this).attr('class'));
    const url = $(this).attr('url');
    loadPartial(get_modal_body('report-channel', 'toggle-vfnn'), url, {
      success() {
        $(".toggle-vfnn").modal({ dynamic: true });
        return focus_modal_elem($(".toggle-vfnn"));
      }
    });
    return false;
  });

  return $(`${target_table_name} .delete-multistep-object, .put-multistep-object`).off('click').on('click', function() {
    const url = $(this).attr('href');
    const action_class = $(this).hasClass('delete-multistep-object') ? 'delete-object-button' : 'put-object-button';
    modal.clientside_modal({
      title: ('<i class="glyphicon glyphicon-remove-sign glyphicon-prepend-text"></i>' +
              $(this).attr('data-original-title'))
      ,
      body: `<div class='alert'>${$(this).attr('action-confirm')}</div></p>`
      ,
      footer: ('<a class="btn btn-primary ' + action_class + '">Yes</a>' +
              '<a class="btn btn-danger" data-dismiss="modal">No</a>')
    });

    $(`${target_table_name} .delete-object-button`).on('click', () => post_as_form(url, {}, {method: 'delete'}));

    $(`${target_table_name} .put-object-button`).on('click', () => post_as_form(url, {}, {method: 'put'}));

    return false;
  });
};

root.create_advfind_buttons = function(model) {
  $('a.a-advfind-' + model).off('click').on('click', function() {
    loadPartial('#modal-body-advfind-' + model, $(this).attr('href'), {
      success() {
        const modal = $('#modal-body-advfind-' + model).closest('.modal');
        modal.modal({ dynamic: true });
        const dateNow = new Date();
        $(".datetimepicker").datetimepicker({
          format:'YYYY-MM-DD HH:mm:ss',
          ignoreReadonly: true,
          useCurrent: false //,
          //defaultDate: dateNow
        });

        $('.clear-date').off('click').on('click', function() {
          $("#" + $(this).attr('for')).val("");
          return $("#" + $(this).attr('for')).next('.bootstrap-datetimepicker-widget').hide();
        });

        focus_modal_elem(modal);
        // Ticket #431: Trigger 'advsearch_loaded' so implementors
        // of the advfind can handle own initializations
        return $('#modal-body-advfind-' + model).trigger('advsearch_loaded');
      }
    });
    return false;
  });
  return $('#advfind-form-submit-' + model).off('click').on('click', function() {
    $('#advfind-form-' + model).submit();
    return false;
  });
};

jQuery(() => $(".btn-contact-support").off('click').on('click', function() {
  const url = $(this).attr('url');
  const title = $(this).attr('dialog_title');
  $('.contact-support .modal-body').html(
    '<div class="spinner"></div>'
  ).load(url, function(a,b,c) {
    if (!sessionExpired(c)) {
      $(".contact-support").modal({ dynamic: true });
      return focus_modal_elem($(".contact-support"));
    }
  });
  if (title) { $('.contact-support .modal-title').html(title); }
  return false;
}));


jQuery(() => $(".btn-account-upgrade").off('click').on('click', function() {
  const url = $(this).attr('url');
  $('.account-upgrade .modal-body').html(
    '<div class="spinner"></div>'
  ).load(url, function(a,b,c) {
    if (!sessionExpired(c)) {
      $(".account-upgrade").modal({ dynamic: true });
      submit_buttons_init();
      return focus_modal_elem($(".account-upgrade"));
    }
  });
  return false;
}));

// Lable toggle in customer account upgrade form
jQuery(() => $("body").on('keyup', '#onboarding_customer_signatory_email, #onboarding_customer_contact_name', function(e){
  $(this).parents('.form-group').toggleClass('focused', e.target.value !== "");
}));

// prevent the issue with the tooltip override on hover in top navigation bar
jQuery(() => $(".info-number").off('click').on('click', function() {
  $(this).blur();
}));
