/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery");
require("moment");
require("bootstrap");
require("datatables.net-bs");
require("datatables.net-responsive-bs");
require("jquery-placeholder");
require("@client-side-validations/client-side-validations");
require("fullcalendar");
require("gentelella");
require("clipboard");
require("jquery-pjax");
require("datejs");
import "./gentelella-custom";
import I18n from "i18n-js";
window.I18n = I18n;

// vendor webpack
import "./vendor/jquery_ui";
import "./vendor/flot_charts";
import "./vendor/tinymce";

// vendor standalone files
require("./vendor/rails.validations.simple_form");
require("./vendor/bootstrap/bootstrap.js");
require("./vendor/bootstrap/bootstrap-tagsinput.js");
require("./vendor/multiple-select.js");
import "./vendor/autocomplete-rails";
import "./vendor/jquery.bootstrap-duallistbox";
import "./vendor/jquery.fileDownload";

// src
import "./src/src_index";
