/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const root = window  //typeof exports !== 'undefined' && exports !== null ? exports : this;

//-------------------------------------------------------------------------------
// get auth token generated by rails server, use it in client side generated form
root.initAuthToken = () => root.AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');

//-------------------------------------------------------------------------------
// common message (alert and error) display with same area across server and client
//
// 1. server render a div with id 'message-area' in each page
// 2. inside 'message-area', a standard bootstrap alert message box rendered
// 3. add extra class 'close-alert' to the 'a.close', so that close fade out instead of remove
// 4. use both "display:none;" and "hide" class to make it work on more browsers
// 5. In this way, it works even on IE8
//-------------------------------------------------------------------------------
root.closeAlertInit = function() {
  $('a.close-alert').off('click').on('click', function() {
    $(this).parent().toggleClass('in', false).css('display', 'none');
    return false;
  });

  // cookie message compliance
  const $cookie_msg = $(".cookie-message").parent().siblings("a");
 // $notification_seen = $(".notification-seen").parent().siblings("a")

  return $cookie_msg.off('click').on('click', function() {
    $(this).parent().toggleClass('in', false).css('display', 'none');
    $.ajax({
      url:  "?cookie_accepted=true",
      type: 'GET'
    });

    return false;
  });
};

root.handleMaxSelectionLimit = function() {
  const maxSelectionLimit = parseInt($("#companies_limit").val());
  const dropdownCheckboxes = $(".ms-drop input[type='checkbox']");
  const value = $("#app_integration_company_app_integration_company_ids").val();

  const companyArray = value ? value.split(",") : [];
  if (companyArray.length >= maxSelectionLimit) {
    dropdownCheckboxes.each(function () {
      if (!$(this).prop("checked")) {
          $(this).prop("disabled", true);
          $(this).closest("label").addClass("disabled");
      }
    })
  } else {
    if (companyArray.length + 1 ==  maxSelectionLimit){
      dropdownCheckboxes.prop("disabled", false);
      $(".ms-drop label").removeClass("disabled");
    }
  }
}

//  $notification_seen.off('click').on 'click', ->
//   $(this).parent().toggleClass('in', false).css('display', 'none')
//    opts = opts ? {}
//    $.ajax
//      url:  "?notification_flow=true",
//      type: opts.method ? 'GET'

//    return false

const new_alert_div = '<div class="alert alert-global alert-danger fade" style="display: none;">' +
            '<a class="close close-alert" data-dismiss="alert" href="#">&times;</a>' +
            '<span class="alert-text"><span>' +
            '</div>';

const alert_div = function(parent) {
  const div = $(parent).find('.alert.alert-global');
  if (div.length > 0) {
    return div.last();
  } else {
    return $(parent).append(new_alert_div).find('.alert.alert-global');
  }
};

const show_msg = function(msg, parent, error) {
  parent = parent != null ? parent : '#message-area';
  const alertdiv = alert_div(parent);
  alertdiv.find('.alert-text').html(msg);
  if (msg!=='') {
    alertdiv.toggleClass('hide', false);
  }
  return alertdiv.css('display', 'block').toggleClass('alert-danger', error).toggleClass('in', true);
};


/*
Below are public APIs
*/
// call on JQuery wrapper object
jQuery.fn.extend({
  init_alert_() {
    if ($(this).find('.alert.alert-global').length > 0) { return $(this).find('.alert.alert-global'); }
    return $(this).append(new_alert_div).find('.alert.alert-global');
  }
  ,
  show_alert(msg) {
    return $(this).init_alert_().css('display', 'block').toggleClass('hide',false).toggleClass('alert-danger', false).toggleClass('in', true).find('.alert-text').html(msg);
  }
  ,
  show_error(msg) {
    return $(this).init_alert_().css('display', 'block').toggleClass('hide',false).toggleClass('alert-danger', true).toggleClass('in', true).find('.alert-text').html(msg);
  }
  ,
  hide_msgs() {
    return $(this).find('.alert.alert-global').toggleClass('in', false).css('display', 'none');
  }
  });

jQuery.fn.addHidden = function(name, value) {
  return this.each(function() {
    const input = $("<input>").attr("type", "hidden").attr("name", name).val(value);
    return $(this).append($(input));
  });
};


// call as standalone function
root.show_alert = (msg, parent) => show_msg(msg, parent, false);

root.show_error = (msg, parent) => show_msg(msg, parent, true);

root.hide_msgs = function(parent) {
  let alertdiv;
  parent = parent != null ? parent : '#message-area';
  return alertdiv = alert_div(parent).toggleClass('in', false);
};

root.fadeInAlert = () => $(".alert").toggleClass('in', true);

//-------------------------------------------------------------------------------
// Tool tips init
//-------------------------------------------------------------------------------
// init all tooltips
root.initToolTip = function(parent) {
  parent = parent != null ? parent : 'body';
  $(parent + ' [title!=""]').tooltip({placement:'auto'});
  //patch to initialize tooltip after pjax call
  //can be removed once all the lists are converted to dt implementation
  return $(document).on('ready pjax:success', function() {
    $('a[title!=""]').tooltip({placement:'auto'});    //tooltip for all anchor tags in the page
    $('span[title!=""]').tooltip({placement:'auto'}); //to cover tooltip for filter buttons
    return $('button[title!=""]').tooltip({placement:'auto'}); //to cover tooltip for filter buttons
  });
};
root.hideToolTip = function(parent) {
  parent = parent != null ? parent : 'body';
  return $(parent + ' [title!=""]').tooltip('hide');
};

//----------------------------------------------------------------------------------
// to support collapse-link toggle in screens with pjax
//----------------------------------------------------------------------------------
const collapse_close_link = function() {
  $('.collapse-link').off('click').on('click', function() {
    const $BOX_PANEL = $(this).closest('.x_panel');
    const $ICON = $(this).find('i');
    const $BOX_CONTENT = $BOX_PANEL.find('.x_content');
    // fix for some div with hardcoded fix class
    if ($BOX_PANEL.attr('style')) {
      $BOX_CONTENT.slideToggle(200, function() {
        $BOX_PANEL.removeAttr("style");
      });
    } else {
      $BOX_CONTENT.slideToggle(200);
      $BOX_PANEL.css('height', 'auto');
    }
    $ICON.toggleClass('fa-chevron-up fa-chevron-down');
  });
  return $('.close-link').on('click', function() {
    const $BOX_PANEL = $(this).closest('.x_panel');
    $BOX_PANEL.remove();
  });
};

//$(document).ready(() => collapse_close_link());

$(document).on('pjax:success', () => collapse_close_link());
//-------------------------------------------------------------------------------
// placeholder support detection
//-------------------------------------------------------------------------------
//root.supportNativePlaceholder = "placeholder" of document.createElement("input")
//root.supportNativePlaceholder = true
root.supportNativePlaceholder = (typeof $.fn.placeholder !== 'undefined') || "placeholder" in document.createElement("input");

//-------------------------------------------------------------------------------
// Cookies
//-------------------------------------------------------------------------------
const getCookieValue = function(key) {
  const currentcookie = document.cookie;
  if (currentcookie.length > 0) {
    let firstidx = currentcookie.indexOf(key + "=");
    if (firstidx !== -1) {
      firstidx = firstidx + key.length + 1;
      let lastidx = currentcookie.indexOf(";",firstidx);
      if (lastidx === -1) { lastidx = currentcookie.length; }
      return unescape(currentcookie.substring(firstidx, lastidx));
    }
  }
  return "";
};

const daysInMilliseconds = numdays => numdays * 24 * 3600 * 1000;

root.setCookie = function(name, value, expire_days) {
  const expiremilliseconds = daysInMilliseconds(expire_days);
  const currdate = new Date();
  const expirationdate = new Date(currdate.getTime() + expiremilliseconds);
  let expire = "";
  // use toGMTString first to keep IE8 compatibility
  try {
    return expire = expirationdate.toGMTString();
  } catch (error) {
    return expire = expirationdate.toUTCString();
  }
  finally {
    document.cookie=`${name}=${value.toString()};expires=${expire}`;
  }
};

root.getCookie = name => getCookieValue(name);

/*
  Convert '2012-02-04' or '2012-12-02T00:13:10Z' to Javascript Date object
  This works on IE8 and other browsers
*/
root.todate = function(timestr) {
  const t = timestr.split('Z')[0].split('T');
  const d = t[0].split('-');
  if (t.length === 1) {
    return new Date(parseInt(d[0], 10), parseInt(d[1], 10)-1, parseInt(d[2], 10));
  } else if (t.length === 2) {
    const tt = t[1].split(':');
    return new Date(parseInt(d[0], 10), parseInt(d[1], 10)-1, parseInt(d[2], 10),
      parseInt(tt[0], 10), parseInt(tt[1], 10), parseInt(tt[2], 10));
  }
};

root.sg_filter_type = function (site_type) {
  sg_details = $("#customer_ultralink_report_channel_service_grade option");

  if (site_type == 1 ) {
    sg_details.each(function () {
      if(!($(this).val() == "")){
        if( $(this).attr("data-is-recorder") == "true" ) {
          $(this).show().prop("disabled", false);
        } else {
          $(this).hide().prop("disabled", true);
        }
      }
    });
  } else if (site_type == 2){
      sg_details.each(function () {
        if(!($(this).val() == "")){
          if( $(this).attr("data-is-camera") == "true" ) {
            $(this).show().prop("disabled", false);
          } else {
            $(this).hide().prop("disabled", true);
          }
        }
      });
 } else {
    sg_details.each(function () {
      if( $(this).attr("data-is-recorder") == "true" ) {
        $(this).hide().prop("disabled", true);
      } else {
        $(this).show().prop("disabled", false);
      }
    });
  }
}

//Show only selfmons for camera as device type
root.camera_agency_filter = function(site_type, agencies_list) {
  if(site_type == 2){
    var self_mon_exist = false;
    var agencyId = agencies_list.parent('select').attr('id');

    agencies_list.each(function(index, element) {
      // Exclude the initial default selection from the agencies dropdown
      if (index > 0) {
        if($(this).attr("data-is-self-monitoring") == "true" ) {
          $(this).prop("selected", true);
          $(this).show().prop("disabled", false);
          self_mon_exist = true
        } else {
          $(this).hide().prop("disabled", true);
        }
      }
    });

    $("#"+agencyId).trigger('change');

    // Set default selected value if selfmon does not exist
    if (!self_mon_exist) {
      $("#"+agencyId).prop('selectedIndex', 0);
    }
  } else {
    agencies_list.each(function () {
      $(this).show().prop("disabled", false);
    });
  }
}

root.validate_self_mon_exists = function(agency_values) {
  var self_mon = 0;
  agency_values.each(function() {
    if( $(this).attr("data-is-self-monitoring") == "true" )
    {
      self_mon = self_mon + 1;
    }
  });
  if (self_mon == 0) {
    return $(".user_request_site_type").hide();
  } else { return false }
}

// Showing device type label based on the SID
root.show_device_type_label = function(type, model){
  device_txt = ""
  if (type == 0) { device_txt = I18n.t("panel")
  } else if (type == 1) { device_txt = I18n.t("recorder")
  } else if (type == 2) { device_txt = I18n.t("camera") }
  if($('.'+model).find('.device_type_label').length > 0){
    $('.'+model).find('.device_type_label').remove();
  }
  $('.'+model).append('<div class="device_type_label">'+I18n.t('selected_device_type', {device: device_txt})+'</div>');
}

// Initialize account number validation for regular flow
root.initAcctnoValidation = function(formIdAttr, acctnoInputAttr, submitButtonSelector, addDeviceFlag = false) {
  var formSelector = $('form[id^="' + formIdAttr + '"]');
  $(document).on("shown.bs.modal", ".modal", function () {
    $(submitButtonSelector).off("click").on("click", function (e) {

      // This ultralink SID check is only for the add remote device user request.
      if (addDeviceFlag) {
        var ultralink_val = $("#ultralink_id").val();

        if (ultralink_val.length === 0) {
          $('.user_request_autocomplete_ultralink').addClass('has-error');
          return false;
        } else {
          $('.user_request_autocomplete_ultralink').removeClass('has-error');
        }
      }

      // Trigger Simple Form validations
      var validators = formSelector[0].ClientSideValidations.settings.validators;
      formSelector.isValid(validators);

      // Custom validation for account number
      var isValid = validateReportingFields(acctnoInputAttr, false);

      // Check if there are any Simple Form validation errors- considering visible ones only
      var simpleFormErrors = formSelector.find(".has-error:visible").length > 0;

      // Submit the form if no errors are found
      if (!simpleFormErrors && isValid) {
        formSelector.submit();
      }
    });
  });
}

// Validate VFNN, Acctno and Format fields in both wizard flow and regular flow scenarios
root.validateReportingFields = function(fieldAttribute, isWizardFlow, siteType, sgValue, selfMonCheck, isAcctno) {
  var fieldClassSelector  = $("." + fieldAttribute);
  var fieldIdSelector     = $("#" + fieldAttribute);
  var isFieldValid        = true;
  isAcctno = typeof isAcctno !== 'undefined' ? isAcctno : true;

  // Helper function to add error message
  function addError(message) {
    fieldClassSelector.addClass("has-error");
    fieldIdSelector.after("<span class='help-block'>" + message + "</span>");
  }

  // Helper function to remove error message
  function removeError() {
    fieldIdSelector.parent().find('.help-block').remove();
    fieldIdSelector.parent().removeClass("has-error");
  }

  // Check if optional parameters are provided
  var validationNeeded;
  if (isWizardFlow) {
    siteType = typeof siteType !== 'undefined' ? siteType : null;
    sgValue  = typeof sgValue !== 'undefined' ? sgValue : null;
    selfMonCheck = typeof selfMonCheck !== 'undefined' ? selfMonCheck : null;

    validationNeeded = !(siteType === "1" && sgValue === "Core" && selfMonCheck === true);
  } else {
    validationNeeded = fieldIdSelector.is(':visible');
  }

  if (validationNeeded) {
    var fieldVal = fieldIdSelector.val();
    if (fieldVal === "") {
      removeError(); // removing existing acctno error message

      addError(I18n.t("errors.messages.blank"));
      isFieldValid = false;
    } else if (!/^[a-fA-F0-9]+$/.test(fieldVal) && isAcctno) {
      removeError(); // removing existing acctno error message

      addError(I18n.t("activerecord.errors.models.report_channel.attributes.acctno.invalid"));
      isFieldValid = false;
    } else {
      removeError(); // removing existing acctno error message
    }
  } else {
    removeError(); // removing existing acctno error message
  }
  return isFieldValid;
};

// Clear error messages for report fields (vfnn, acctno, and format)
// when switching to Core subscription plan and moving to the next step
root.clearWizardReportFieldErrors = function() {
  var siteType = $('#site_type').val();
  var subscriptionPlan = $("#customer_ultralink_subscription_plan").val();

  if (siteType == 1 && subscriptionPlan == "Core") {
    var reportfields = [
      '#customer_ultralink_report_channel_vfnn',
      '#customer_ultralink_report_channel_acctno',
      '#customer_ultralink_report_channel_format'
    ];

    reportfields.forEach(function(field) {
      var parent = $(field).parent();
      parent.find('.help-block').remove();
      parent.removeClass('has-error');
    });
  }
}

//Based on device type lookup ajax response, here we are preparing service grades
root.prepare_service_grades = function() {
  const is_self_monitoring = $("#modal-body-new-user-request #user_request_agency_id option:selected").attr("data-is-self-monitoring");

  var site_type = $("#site_type").val();
  filter_service_grade_by_smonitoring("new", "user-request", is_self_monitoring, site_type, "customer_ultralink_report_channel")
}

root.site_type_filter = function() {
  // user request site type dropdown list
  sg_filter_type("0");
  $("#modal-body-new-user-request").on("change", "#user_request_site_type", function(){

    if ($(this).val() == 1) {
      //if recorder selects, filter self monitor central stations only.
      // branch_details = $("#user_request_agency_id option");
      // branch_details.each(function () {
      //   if( $(this).attr("data-is-self-monitoring") == "true" ) {
      //     $(this).show().prop("disabled", false);
      //   } else {
      //     $(this).hide().prop("disabled", true);
      //   }
      // });

      recorder_input_fields();
      sg_filter_type($(this).val());

      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
    } else {
      //if panel selects, filter all agencies.
      branch_details = $("#user_request_agency_id option");
      branch_details.each(function () {
        $(this).show().prop("disabled", false);
      });

      panel_input_fields();
      sg_filter_type($(this).val());

      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
    }

    //refresh to agencies to select first value
    $("#user_request_agency_id").prop('selectedIndex',0);
  });
}

const initNotification = () => $(".generic_notif").off('click').on('click', () => $.ajax({
  url:  '/get_notifications',
  type: 'GET',
  "beforeSend"(xhr) {
    return $('.drop-content').html('<div class="spinner"></div>');
  },
  "success"(data, status, xhr) {
    $(".drop-content").empty();
    if (!sessionExpired(xhr)) {
      $(".drop-content").append(data);
      const notif_count = $("#notification_count").val();
      $(".notif_count").html(notif_count);
      if (!(notif_count > 0)) {
        return $(".drop-content").html("<li class =alert-warning> <div class='col-md-13 col-sm-14 col-xs-11 pd-l4'><span>" +
        I18n.t("no_data_available") + "</span></div></li>");
      }
    }
  }

}));

//-------------------------------------------------------------------------------
// file download init
//-------------------------------------------------------------------------------
const initDownload = function() {

  $('a.export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("download_failed"));
      }
    });
    return false;
  });

  $('a.customer-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("customer_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("customer_download_failed"));
      }
    });
    return false;
  });

  $('a.company-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("company_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("company_download_failed"));
      }
    });
    return false;
  });

  $('a.user-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("user_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("user_download_failed"));
      }
    });
    return false;
  });

  $('a.sim-management-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("sim_management_export_completed"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("sim_management_download_failed"));
      }
    });
    return false;
  });

  $('a.app-integration-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t('app_integration_download_successful'));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t('download_failed', {type: I18n.t("app_integration").toLowerCase() }));
      }
    });
    return false;
  });

  $('a.plugin-license-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t('plugin_license_download_successful'));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t('download_failed', {type: I18n.t("plugin_license").toLowerCase() }));
      }
    });
    return false;
  });

  $('a.support-contact-user-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("support_contact_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("support_contact_download_failed"));
      }
    });
    return false;
  });

  $('a.oh2000-event-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("oh2000_event_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("oh2000_event_download_failed"));
      }
    });
    return false;
  });

  return $('a.user-request-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {format: 'xlsx'},
      successCallback(url) {
        return show_alert(I18n.t("user_request_download_successful"));
      }
      ,
      failCallback(url) {
        return show_error(I18n.t("user_request_download_failed"));
      }
    });
    return false;
  });


};
//-------------------------------------------------------------------------------
// placeholder init
//-------------------------------------------------------------------------------
jQuery.fn.extend({
  initPlaceHolder() {
    //$('input, textarea', this).placeholder()
    return $(':input:not(:password), textarea', this).placeholder();
  }
});

//-------------------------------------------------------------------------------
// javascript init per page (temperory solution)
//-------------------------------------------------------------------------------
const page_tags = {};
root.tag_page = function(page_name) {
  page_tags[page_name] = true;
  if (arguments.length === 1) { return; }
  return __range__(1, arguments.length-1, true).map((i) =>
    (page_tags[arguments[i]] = true));
};

root.run_ontag = function(tag, fn) {
  if (page_tags[tag] != null) { return fn(); }
};

root.page_tag_exists = tag => page_tags[tag] != null;

//-------------------------------------------------------------------------------
// clientside form init
//-------------------------------------------------------------------------------
root.post_as_form = function(url, data, opts) {
  if (!data) { data = {}; }
  if (!opts) { opts = {}; }
  let method = 'post';
  if (opts['method'] === 'get') {
    method = 'get';
  } else if (opts['method'] === 'put') {
    data['_method'] = 'put';
  } else if (opts['method'] === 'delete') {
    data['_method'] = 'delete';
  }

  let inputs = '<input type="hidden" name="authenticity_token" value="' + AUTH_TOKEN + '" />';
  for (let name in data) {
    const value = data[name];
    inputs += ('<input type="hidden" name="' + name + '" value="' + value + '" />');
  }
  $('<form method="' + method + '" action="' + url + '" />')
    .append(inputs)
    .appendTo('body')
    .submit();
  return false;
};

//-------------------------------------------------------------------------------
// global init
//-------------------------------------------------------------------------------
jQuery(function() {

  $("[rel=tooltip]").tooltip({placement:'auto'});
  initToolTip();
  fadeInAlert();
  closeAlertInit();
  initValidationOnModal();
  // init placeholder for inputs on page
  $('body').initPlaceHolder();
  // init placeholder for inputs in modal
  initPlaceHolderOnModal();
  initAuthToken();
  initDownload();
  initNotification();
  // TODO: enable to support non-modal form inputs
  // initFloatLabels()

  $("a[data-popup]").off('click').on('click', function(e) {
    window.open($(this)[0].href, '_blank', 'toolbar=0,location=0,menubar=0,width=960,height=640');
    return e.preventDefault();
  });
  return $("a[data-extlink]").off('click').on('click', function(e) {
    if (window.opener && !window.opener.closed) {
      window.opener.open($(this)[0].href);
    } else {
      window.open($(this)[0].href, '_blank', 'toolbar=1,location=1,menubar=1');
    }
    return e.preventDefault();
  });
});

// Based on a selected country, update the state input field
root.country_select_init = function(model, country_id, state_id) {
  country_id = country_id != null ? country_id : "#"+model+"_country";
  state_id = state_id != null ? state_id : "#"+model+"_state";
  const default_val = $(state_id).val();
  if (($(country_id).length + $(state_id).length) < 2) { return; }
  return $(country_id).on('change', function() {
    $(state_id).attr("disabled", true);
    const url = "/countries/" + $(country_id).val() + "/" + model;
    if ($(country_id).val() !== "") {
      return $(state_id).parent().load(url,
      function() {
        $(state_id).enableClientSideValidations();
        clear_state_label(state_id);
        return restore_state_selection(state_id,default_val);
      });
    }
    else{
      $(state_id).val('');
    }
    clear_state_label(state_id);
  });
};

const clear_state_label  = function(state_id) {
    if ($(state_id).parent().attr('class').indexOf('form-group') == -1)
    {
      $(state_id).prev('label').remove();
    }
};

// With sales region filter we limit the country list
// doing so we need to trigger change event for the country dropdown
// to make sure only relevant states are loaded
// and state dropdown still retains any pre selection
root.restore_state_selection = function(state_id,default_val) {
  if (default_val !== "") {
    if ($(state_id).prop('type') === 'text') {
      $(state_id).val(default_val);
    } else {
      $(state_id+" option").each(function() {
        if ($(this).val() === default_val) {
          return $(this).attr("selected", true);
        }
      });
    }
  }
  return $(state_id).trigger('change');
};

// Based on a selected country, update the timezone input field
root.update_timezones= function(model, country_id) {
    const url = "/country/timezones/" + $(country_id).val() + "/"+model;
    $("#"+model+"_tz").attr("disabled", true);
    return $("#"+model+"_tz").parent().load(url, () => $(country_id));
  };

// Based on a selected country, update the service grade input field
root.update_service_grades= function(model, country_id) {
    const url = "/country/service_grades/" + $(country_id).val() + "/"+model;
    return $("#"+model+"_service_grade").parent().load(url, () => $(country_id));
  };

// Based on a selected country, update the companies list input field
root.update_companies_list= function(model, country_id) {
    const url = "/country/companies_list/" + $(country_id).val() + "/"+model;
    return $("#"+model+"_branch_id").parent().load(url, () => $(country_id));
  };


root.filter_service_grade_by_smonitoring = function(mode, model, self_monitoring_cs, site_type, id_prefix) {
  const sg_select   = $("#modal-body-" + mode + "-" + model + " #"+ id_prefix + "_service_grade");
  const sg_options  = $("#modal-body-" + mode + "-" + model + " #"+ id_prefix + "_service_grade option:not(:first-child)");
  if (self_monitoring_cs !== undefined && site_type == "0"){
    if (self_monitoring_cs.toString() == "true"){
      sg_options.each(function(){
        if ($(this).attr("data-is-recorder") == "true") {
          return $(this).prop("selected", false).hide().attr("disabled", true);
        } else {
          return $(this).prop("selected", false).show().prop("disabled", false);
        }
      });
    }
    else if (self_monitoring_cs.toString() == "false"){
      sg_options.each(function(){
        if ($(this).val() === "") {
        }
        else if ($(this).attr("data-is-recorder") == "true") {
          return $(this).prop("selected", false).hide().attr("disabled", true);
        }
        else if ($(this).attr("data-is-lower-grade") == "true") {
          return $(this).prop("selected", false).hide().attr("disabled", true);
        } else {
          return $(this).prop("selected", false).show().prop("disabled", false);
        }
      });
    }
    if($("#customer_ultralink_report_channel_service_grade").val() === ""){
      $(".sg_features_block").hide();

    }
  }
  else if (self_monitoring_cs !== undefined && ((site_type == 1) || (site_type == 2))){
      if (site_type == 2) {
        sg_options.each(function () {
          if(!($(this).val() == "")){
            if( $(this).attr("data-is-camera") == "true") {
              $(this).show().prop("disabled", false);
            } else {
              $(this).hide().prop("disabled", true);
            }
          }
        });
        $(".sg_features_block").hide();
      } else if (self_monitoring_cs.toString() == "true"){
        sg_options.each(function(){
          if ($(this).attr("data-is-recorder") == "false") {
            return $(this).prop("selected", false).hide().attr("disabled", true);
          } else {
            return $(this).prop("selected", false).show().prop("disabled", false);
          }
        });
      }
      else if (self_monitoring_cs.toString() == "false"){
        sg_options.each(function(){
          if ($(this).val() === "") {
          }
          else if ($(this).attr("data-is-recorder") == "false") {
            return $(this).prop("selected", false).hide().attr("disabled", true);
          }
          else if ($(this).attr("data-is-lower-grade") == "true") {
            return $(this).prop("selected", false).hide().attr("disabled", true);
          } else {
            return $(this).prop("selected", false).show().prop("disabled", false);
          }
      });
      }
    } ;
}

root.recorder_input_fields = function (type) {
  $(".customer_ultralink_tz").show();
  $(".customer_ultralink_report_channel_vfnn").show();
  $(".customer_ultralink_report_channel_acctno").show();
  $(".customer_ultralink_report_channel_format").show();
  $(".sg_features_block").hide();
  $("#customer_ultralink_report_channel_service_grade").show();
}

root.display_sid_acts_error  = function () {
  $('.bootstrap-tagsinput').addClass('bootstrap_tagsinput_error_msg');
  $('.tagsinput_acct').addClass('tag_add_error_msg');
}

root.remove_sid_acts_error  = function () {
  $(".bootstrap-tagsinput").removeAttr('tag_add_error_msg');
  $('.tagsinput_acct').addClass('tag_remove_error_msg');
}

root.change_autocomplete_placeholder = function(autocomplete_id, place_txt, display_txt) {
  autocomplete_label = "autocomplete_label";
  sg_autocomplete = $(autocomplete_id);
  sg_autocomplete.off('keyup').on('keyup', (function(_this) {
    return function() {
      return toggle_autocomplete_placeholder($("#"+autocomplete_label), sg_autocomplete, place_txt, display_txt);
    };
  })(this));
  sg_autocomplete.on('railsAutocomplete.select', function(event, data) {
      if (data.item.label === I18n.t('no_results_found')) {
        sg_autocomplete.val("");
        toggle_autocomplete_placeholder($("#"+autocomplete_label), sg_autocomplete);
        return false;
      }
  });
}

toggle_autocomplete_placeholder = function(label, autocomplete_field_id, place_txt, display_txt) {
  label.text(display_txt);
  if (autocomplete_field_id.val() === "") {
    label.text("");
    return autocomplete_field_id.attr("placeholder", place_txt);
  }
};

root.load_company_defaults = function(){
  var preferred_agency = $("#customer_ultralink_report_channel_preferred_agency_id");
  var preferred_vfnn   = $("#customer_ultralink_report_channel_preferred_report_channel_vfnn");
  var preferred_format = $("#customer_ultralink_report_channel_preferred_report_channel_format");

  if(!($("#user_request_agency_id").val() > 0)){
    if (preferred_agency.val() > 0 ){
      let agency_option = $("#user_request_agency_id").find("option[value='"+preferred_agency.val()+"']:not([disabled])");
      if(agency_option.length > 0){
        $("#user_request_agency_id").val(preferred_agency.val());
      }
      $("#user_request_agency_id").trigger('change');
    }
  }

  var vfnnValue = $("#customer_ultralink_report_channel_vfnn").val();
  if(vfnnValue !== undefined && vfnnValue !== null && !(vfnnValue.length > 0)){
    if (preferred_vfnn.length > 0 ){
      let vfnn_option = $("#customer_ultralink_report_channel_vfnn").find("option[value='"+preferred_vfnn.val()+"']:not([disabled])");
      if(vfnn_option.length > 0){
        $("#customer_ultralink_report_channel_vfnn").val(preferred_vfnn.val());
      }
      $("#customer_ultralink_report_channel_vfnn").trigger('change');
    }
  }

  var formatValue = $("#customer_ultralink_report_channel_format").val();
  if(formatValue !== undefined && formatValue !== null && !(formatValue.length > 0)) {
    if (preferred_format.length > 0 && $("#customer_ultralink_report_channel_vfnn").val().length > 0 ){
      let format_option = $("#customer_ultralink_report_channel_format").find("option[value='"+preferred_format.val()+"']:not([disabled])");
      if(format_option.length > 0){
        $("#customer_ultralink_report_channel_format").val(preferred_format.val());
        $("#customer_ultralink_report_channel_format").trigger('change');
      }
    }
  }
}

hide_select_options = function(input_id) {
  $(`${input_id} option`).slice(1).each(function() {
    return $(this).prop("selected", false).hide();
  });
  $(input_id).attr("readonly", true)
}

load_sg_addon_for_wizard = function(device_type) {
  if(device_type == '0'){
    var selfMon_check = $("#user_request_agency_id").find("option:selected").attr("data-is-self-monitoring") == "true";
    var vv_add_on = $("div[class*='customer_ultralink_report_channel_video_verification']");
    var oh_add_on = $("div[class*='customer_ultralink_report_channel_oh_reporting']");
    // If subscription plan is custom service grade and its lower service grade then
    // If user selects self monitor CMS then OH addon should show else OH addon should hide.
    var subscription = '#customer_ultralink_subscription_plan';
    if($(subscription + " option:selected").attr("data-is-custom-sg") == 'true'){
      if($(subscription + " option:selected").attr("data-is-lower-grade") == 'true' && selfMon_check){
        oh_add_on.show();
        vv_add_on.hide();
      } else{
        oh_add_on.hide();
        vv_add_on.show();
      }
      return hideAddonResetFeatures();
    }

    if ($("#customer_ultralink_subscription_plan").val() == "Core" && selfMon_check){
      var subscription_plan = $("#customer_ultralink_subscription_plan").val();
      var ethernetChecked = $('#ethernet').is(':checked');
      var cellularChecked = $('#cellular').is(':checked');

      $.ajax({
        url: "/ultralinks/generate_service_grade",
        type: "GET",
        data: { device_type: device_type,
                subscription_plan: subscription_plan,
                ethernetChecked: ethernetChecked,
                cellularChecked: cellularChecked },
        success(data, status, xhr) {
          if (!sessionExpired(xhr)) {
            $('.filter_service_grades').hide();
            $("#custom_sg").val('');
            $("#fcode").val('');
            var sg_value = Object.keys(data["service_grade"])[0];
            var fcode = data["service_grade"][sg_value];
            vv_add_on.hide();
            $("#custom_sg").val(sg_value);
            $("#fcode").val(fcode);
            $(".sg_features_block").show();
            refresh_feature_list("#site_type", fcode)
            return hideAddonResetFeatures();
          }
        }
      });
    }
    else {
      // Higher SG selection
      oh_add_on.hide();
      vv_add_on.show();
      return hideAddonResetFeatures();
    }
  }
}

//SID lookup from wizard flow
process_sid_input = function(){
  $('#customer_ultralink_sid').on('keyup', function() {
    $("#wizard_sid").val(' ')
  });
  $('#customer_ultralink_sid').on('keyup blur', function() {
    root_sid_div = $('#customer_ultralink_sid').parent();
    var usid = $('#customer_ultralink_sid').val();
    // If SID is blank
    if (usid !== "") {
      if($('.customer_ultralink_sid').find('.help-block').length > 0){
        $('.customer_ultralink_sid').removeClass("has-error");
        $('.customer_ultralink_sid').find('.help-block').remove();
      }
      if(usid.length == 12){
        if(!($("#wizard_sid").val() == usid)){
          $("#wizard_sid").val(usid);
          $.ajax({
           url: "/ultralinks/get_device_details",
            type: "GET",
            data: { sid: usid, request_type: "wizard"},
            success(data, status, xhr) {
              if (!sessionExpired(xhr)) {
                $('#site_type').val(data["device_type"]);
                $("#customer_ultralink_subscription_plan option:not(:first-child)").remove();

                // Hide add-ons when device type select as Recorder
                if ( ($('#site_type').val() == 1) || ($('#site_type').val() == 2)) {
                  hide_wizard_step(I18n.t('addon'))
                  hide_wizard_step(I18n.t('connectivity'))
                }
                else {
                  show_wizard_step(I18n.t('addon'))
                  show_wizard_step(I18n.t('connectivity'))
                }
                var progress_line = $(".f1-steps").find('.f1-progress-line');
                adjust_size_progress_bar();
                progress_bar(progress_line, 'right')
                $.each(data["subscription_plans"], function(key, value) {
                  $('<option>').attr({
                    'data-is-custom-sg': value['data-is-custom-sg'],
                    'data-standard-fcode': value['data-standard-fcode'],
                    'data-is-lower-grade': value['data-is-lower-grade'],
                    value: key
                  }).text(key).appendTo('#customer_ultralink_subscription_plan');
                });

                show_device_type_label(data["device_type"],"customer_ultralink_sid");
              }
            },
          });
        }
      } else {
        // The existing device type label needs to be removed if SID is invalid.
        if($('.customer_ultralink_sid').find('.device_type_label').length > 0){
          $('.customer_ultralink_sid').find('.device_type_label').remove();
        }
        $("#customer_ultralink_subscription_plan").prop('selectedIndex', 0);
        $("#customer_ultralink_subscription_plan option:not(:first)").remove();
        // At the same time system has to show invalid SID error msg if SID length is less than 12 chars
        if (($('.customer_ultralink_sid').hasClass('has-error'))) {
          $('.customer_ultralink_sid').find('.help-block').remove();
          $('.customer_ultralink_sid').removeClass("has-error");
        }
        $('.customer_ultralink_sid').addClass(" has-error");
        $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t('sid_is', {sid: I18n.t("invalid")})+ "</span>" );
      }
    } else {
      // Hide the Device Type Label, if it already exists and the user attempts to delete an existing SID.
      if ($('.customer_ultralink_sid').find('.device_type_label').length > 0) {
        $('.customer_ultralink_sid').find('.device_type_label').remove();
      }
      $("#customer_ultralink_subscription_plan").prop('selectedIndex', 0);
      $("#customer_ultralink_subscription_plan option:not(:first)").remove();
      // If the block enters because "SID" is empty, display the message "Cannot be blank."
      if (!($('.customer_ultralink_sid').hasClass('has-error'))) {
        $('.customer_ultralink_sid').addClass(" has-error");
        $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
    }
  });
};

progress_bar = function(progress_line_object, direction){
  var number_of_steps = $('.f1-steps > div').not('[style*="display: none"]').length - 1;
  var now_value = $('.f1-steps > div.f1-step.activated, .f1-steps > div.f1-step.active').length;

  var percentage = ((now_value - 1) / (number_of_steps - 1)) * 100;
  $('.f1-progress-line').css('width', percentage + '%');
}

adjust_size_progress_bar = function(){
  var numberOfSteps = $('.f1-steps > div').not('[style*="display: none"]').length - 1;
  if (numberOfSteps == 5){
    $('.f1-step').css({
        'position': 'relative',
        'float': 'left',
        'width': '20%' ,
        'padding': '0 5px'
    });
  } else if(numberOfSteps == 4){
    $('.f1-step').css({
        'position': 'relative',
        'float': 'left',
        'width': '25%',
        'padding': '0 5px'
    });
  } else if(numberOfSteps == 3){
    $('.f1-step').css({
        'position': 'relative',
        'float': 'left',
        'width': '30%',
        'padding': '0 5px'
    });
  }
}


//Hide Video Verification for lower service grade(core)
root.restrict_addons_for_core = function(src){
  let self_monitoring_cs, is_self_mon, vv_add_on, oh_add_on;
  $( "div[class*='customer_ultralink_report_channel_video_verification']" ).show();
  $("#customer_ultralink_report_channel_service_grade").off("change").on('change', function() {
    vv_add_on = $("div[class*='customer_ultralink_report_channel_video_verification']");
    oh_add_on = $("div[class*='customer_ultralink_report_channel_oh_reporting']");
    oh_chechbox = $("input[name*='[oh_reporting']");
    if(src == "user_request"){
      self_monitoring_cs = $("#user_request_agency_id option:selected").attr("data-is-self-monitoring");
    }
    else {
      self_monitoring_cs = $("#is_self_monitoring").val();
    }
    if($(this).find(':selected').attr("data-is-lower-grade") == "true"){
      oh_chechbox.prop('checked', false);
      vv_add_on.hide();
      if(self_monitoring_cs && self_monitoring_cs.toString() == "true"){
        oh_add_on.show();
      }
      else {
        oh_chechbox.prop('checked', false);
        oh_add_on.hide();
      }
      if ($(this).find(':selected').attr("data-is-recorder") == "true") {
        $(".customer_ultralink_report_channel_vfnn").hide();
        $(".customer_ultralink_report_channel_acctno").hide();
        $(".customer_ultralink_report_channel_format").hide();
      }
      else
      {
        $(".customer_ultralink_report_channel_vfnn").show();
        $(".customer_ultralink_report_channel_acctno").show();
        $(".customer_ultralink_report_channel_format").show();
      }
    } else {
      vv_add_on.show();
      oh_chechbox.prop('checked', false);
      oh_add_on.hide();
      $(".customer_ultralink_report_channel_vfnn").show();
      $(".customer_ultralink_report_channel_acctno").show();
      $(".customer_ultralink_report_channel_format").show();
    }
    oh_add_on.trigger('change');
  });
}

//Default fields for OH Reporting
root.oh_reporting_input_fields = function(modal, site_type_identifier){
  $("div[class*='"+modal+"_oh_reporting']").off("change").on('change', function() {
    var site_type = $("#site_type").val();
    if($("input[name*='[oh_reporting']").is(":checked")){
      $("."+modal+"_vfnn").hide();
      $("."+modal+"_acctno").hide();
      $("."+modal+"_format").hide();
    } else if (site_type == 0) {
      $("."+modal+"_vfnn").show();
      $("."+modal+"_acctno").show();
      $("."+modal+"_format").show();
    }
  });
   $("div[class*='"+modal+"_oh_reporting']").trigger("change");
}

root.panel_input_fields = function () {
  $(".customer_ultralink_tz").show();
  $(".customer_ultralink_report_channel_branch_id_autocomplete").show();
  $(".customer_ultralink_report_channel_recorder_branch_id_autocomplete").hide();
  $(".customer_ultralink_report_channel_vfnn").show();
  $(".customer_ultralink_report_channel_acctno").show();
  $(".customer_ultralink_report_channel_format").show();
}

// Feature code changes BEGIN
root.create_sg_feature_list = function(modal, mode){
  $("body").on("change", modal, function(){
    if ($(mode).val() == 1 || $(mode).val() == 2) {
      return $(".sg_features_block").hide();
      } else {
        $(".sg_features_block").show();
        if ($(modal).val() !== "") {
          const fcode = $(`${modal} option:selected`).attr("data-standard-fcode");
          $(".sg_features_block").show();
          return refresh_feature_list(mode,fcode);
        } else {
          return $(".sg_features_block").hide();
        }
    }
  });

  return $(".reset_features").off('click').on('click', function() {
    let standard_fcode;
    standard_fcode = $(`${modal} option:selected`).attr("data-standard-fcode");
    if (standard_fcode === undefined){
      standard_fcode = $("#report_channel_id").data("standard-fcode");
    }
    refresh_feature_list(mode,standard_fcode);
    $(`${modal}`).trigger('change');
  });
};

root.hideAddonResetFeatures = function () {
  // Verifying whether every add-on feature is visible or not
  var allHidden = true;
  formGroupDiv = $('#sg_features_list').find('div.form-group')

  if(formGroupDiv.length > 0 ){
    formGroupDiv.each(function() {
      if ($(this).is(':visible')) {
        allHidden = false;
        return false;
      }
    });
  }
  //if all add-on features are hidden, then hiding the alert_text and reset_features button
  var sgFeaturesBlock = $('.sg_features_block');
  if (allHidden) {
    sgFeaturesBlock.find('.alert_text, .reset_features').hide();
  } else {
    sgFeaturesBlock.find('.alert_text, .reset_features').show();
  }
}

root.refresh_feature_list = function (mode, fcode) {
  __range__(0, fcode.length, false).map((i) => $(`#sg_feature_${i}`).prop('checked', get_bit_status(fcode[i])));

  // if add-ons are not available, hiding the alert_message and reset_features button
  hideAddonResetFeatures();
}

root.get_bit_status = function(bit) {
  if (bit === "1") {
    return true;
  } else {
    return false;
  }
};

root.device_type_formats = function(site_type, format_option_id, recorder_formats, camera_formats) {
  if (site_type == '1' || site_type == '2'){
    var format_type = (site_type == '1') ? recorder_formats.split(',') : camera_formats.split(',')
    $("#"+format_option_id + " option").each(function() {
      if(Array.from(format_type).includes($(this).val()) || $(this).val() == "") {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    });
  }
}

// Feature code changes END


root.approval_pagination = () => $(".approval_history_pagination a").off('click').on('click', function() {
  if ($(this).parent('.active').length === 1) { return false; }
  const url = $(this).attr("href");
  if (url.length > 3) { $('.view-dialog .modal-body').html('<div class="spinner"></div>').load(url, function(a,b,c) {
    if (!sessionExpired(c)) {
      return approval_pagination();
    }
  }); }
  return false;
});

$("body").ready(setTimeout((() => window.scrollTo(0, 1)), 100));

//-------------------------------------------------------------------------------
// Attach indicator to particular parent div/element
// parent div would be set to relative position
//-------------------------------------------------------------------------------
root.initAjaxIndicator = function(parent, id) {
  // Setup the ajax indicator
  $(parent).append('<div id="' + id + '"><p><img src="/assets/loading.gif"></p></div>');
  $(parent).css({position:"relative"});
  return $(`#${id}`).css({
    display:"none",
    position:"absolute",
    left:"50%",
    top:"50%",
    width:"32px",
    height:"32px",
    marginLeft :"-16px",
    marginTop:"-16px"
    });
};

root.showAjaxIndicator = id => $(`#${id}`).show();
root.hideAjaxIndicator = id => $(`#${id}`).hide();

root.avoid_multiple_submit = (form_id, button_id) => $(`#${button_id}`).off('click').on('click', function() {
  if (form_id.match('^new_')) {
    $(`#${form_id}`).find('input, select').each(function() {
      $(this).trigger('blur');
    });
  }
  const form_validators = $(ClientSideValidations.selectors.forms[`${form_id}`]).validate();
  if ($(`#${form_id}`).isValid(form_validators)) {
    $(`#${button_id}`).attr('disabled', true);
    $(`#${button_id}`).off('click');
    return $(`#${form_id}`).submit();
  }
});

//-------------------------------------------------------------------------------
// Initialize event handlers for float labels on inputs, selects, and textareas
//-------------------------------------------------------------------------------
root.initFloatLabels = function(isFloated) {
  if (isFloated == null) { isFloated = true; }
  const getFormId = elem => $(elem).closest('form').attr('id');

  const getElemId = elem => $(elem).attr('id');

  const getLabel = elem => $('#' + getFormId(elem) + ' label[for="' + getElemId(elem) + '"]');

  const init_float_elem = function(elem) {
    let new_label_caption;
    const label = getLabel(elem);
    label.css('visibility', 'visible');

    if ($(elem).hasClass('readonly') && !label.hasClass('disabled')) {
      label.addClass('disabled');
    }

    const select_option = $(elem).find('option').first();
    if (select_option.length > 0) {
      if ((select_option.val() === undefined) || (select_option.val() === "")) {
        if (isFloated) { label.css('visibility', 'hidden'); }
        new_label_caption = select_option.text();
      }
    }

    const placeholder = $(elem).attr('placeholder');
    if (placeholder) {
      new_label_caption = placeholder;
      if (isFloated) { $(elem).prop('placeholder', ''); }
    }

    // IMPT: if an input (select) has :label_override => true,
    //       a corresponding :label => t('label') needs to be defined as well.
    // USAGE: override default label caption (first option text) with the one specified
    if (!Boolean($(elem).attr('label_override'))) { return label.text(new_label_caption); }
  };

  // we changed from 'on' to 'delegate' in order to support cases when
  // inputs are getting removed and rerendered. if we use 'on', the newly
  // added elements will not raise the events we need. http://bit.ly/1Tys5eS
  // Reverting to 'on' as part of Jquery 3 upgrade
  $('body').on('focus blur', 'input, select, textarea', function(e) {
    if (!support_float_label) { return; } // show float only on supported browsers
    if (!getElemId(this)) { return; }        // ensure form defined element
    if (!getFormId(this)) { return; }        // ensure element is a form element
    init_float_elem(this);

    const elem = $(this); // cache
    if ((elem.prop('nodeName') === "SELECT") && (elem.attr('size') > 1)) {
      elem.parents('.form-group').toggleClass('focused', true); // always floated
    } else {
      elem.parents('.form-group').toggleClass('focused', this.value.length > 0);
    }
  });
  $('input, select, textarea').trigger('blur');

  // TODO: investigate if bindings below will cause any perf issue or
  //       cause any side effects ie ghost events
  $('body').on('change', 'input, select, textarea', function(e) {
    if (!support_float_label) { return; } // show float only on supported browsers
    init_float_elem(this);
    if ($(this).hasClass('multiselect-search')) {
      return $(this).parents('.form-group').toggleClass('focused', true);
    } else {
      return $(this).parents('.form-group').toggleClass('focused', this.value.length > 0);
    }
  });

  return $('body').on('keyup', 'input, select, textarea', function(e) {
    if (!support_float_label) { return; } // show float only on supported browsers
    init_float_elem(this);
    if ($(this).hasClass('multiselect-search')) {
      return $(this).parents('.form-group').toggleClass('focused', true);
    } else {
      return $(this).parents('.form-group').toggleClass('focused', e.target.value !== "");
    }

  });
};

root.scrollup = function() {
  $('html, body').animate({ scrollTop: 0 }, 350);
};

//-----------------------------------------------------
// Based on the selection of the company type,
// load the companies' information in the dropdown
//------------------------------------------------------
root.reload_company_data = function (company_data, req_type) {
  $("."+req_type+"_sales_region input[type=checkbox]").prop("disabled", true);
  $.ajax({
    url: "/companies/filter_companies",
    data: company_data,
    type: "GET",
    dataType: "JSON",
    success: function (data, status, xhr) {
      if (!sessionExpired(xhr)) {
        if ( !(data.result_ag == null) && data.result_ag.length > 0 ) {
          populateMultiSelect(req_type + "_agency_id", data.result_ag);
        }
        if ( !(data.result_br == null) && data.result_br.length > 0 ) {
          populateMultiSelect(req_type + "_branch_id", data.result_br);
        }
        if ( !(data.result_dist == null) && data.result_dist.length > 0 ) {
          populateMultiSelect(req_type + "_distributor_id", data.result_dist);
        }
        if ( !(data.result_sub_dealer == null) && data.result_sub_dealer.length > 0 ) {
          populateMultiSelect(req_type + "_sub_dealer_id", data.result_sub_dealer);
        }
      }
      $("."+req_type+"_sales_region input[type=checkbox]").prop("disabled", false);
      $("."+req_type+"_sub_button").prop('disabled', false);
    },
    error: function (xhr, status, errors) {
      console.log("ajax error block");
      $("."+req_type+"_sales_region input[type=checkbox]").prop("disabled", false);
    }
  });
};

function populateMultiSelect(id, data) {
  if (data != null && data.length > 0) {
    $("#" + id).multiselect('dataprovider', data);
  }
}

$(document).on('focus', 'input[data-autocomplete]', function() {
  if (!this.railsAutoCompleter) {
    this.railsAutoCompleter = new (jQuery.railsAutocomplete)(this);
  }
});

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}

$.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null){
       return null;
    }
    else {
       return decodeURI(results[1]) || 0;
    }
}

root.toggleTooltip = function(dtypeId) {
  if (dtypeId) {
    var tooltip = $('#customTooltip-'+dtypeId);

    // Hide all tooltips except the one associated with the clicked filter icon
    $('.custom-tooltip').not(tooltip).hide();

    // Toggle the visibility of the clicked tooltip
    tooltip.toggle();
  }
}
