/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready, init_datatable, init_search, plugin_license_new_init, plugin_license_edit_init;

plugin_license_new_init = function () {

  var mode;
  mode = 'new';
  // Based on size of steps reducing the width of each step navigation div
  // Needs to move with CSS
  $('.f1-step').css({
      'position': 'relative',
      'float': 'left',
      'width': '30%',
      'padding': '0 5px'
  });

  $("#new-form-next-plugin-license").show();
  $("#new-form-previous-plugin-license").hide();
  $("#new-form-submit-plugin-license").hide();
  // When user clicks on Next button logic
  $('#new-form-next-plugin-license').off("click").on("click", function (e) {
    var classNames = [];
    var stepClasses = $('[class*=step_0]').not('.hidden-element');
    stepClasses.each(function() {
      classNames.push($(this).attr("class"));
    });
    var activeClass = classNames[0].split(" ");

    if(validateActiveForm(activeClass[0], mode)){
      var progress_line = $(".f1-steps").find('.f1-progress-line');
      var current_active_step = $(".f1-steps").find('.f1-step.active');
      $('.' + activeClass.join(".")).fadeOut(400, function() {
        next_class = current_active_step.removeClass('active').addClass('activated').next();
        // Check if next parent div having display non class, then move next class and add active
        if(next_class.css('display') !== 'none'){
          // check if next class having display not none
          next_class.addClass('active')
        } else {
          next_class.next().addClass('active')
        }
        progress_bar(progress_line, 'right');
        $("#new-form-previous-plugin-license").show();
        $('.' + activeClass[0]).addClass('hidden-element');
        $('.' + activeClass[0]).removeClass('active');
        var nextStepElement  = $('.' + activeClass[0]).next('[class^="step_"]');
        if(next_class.css('display') == 'none'){
          nextStepElement = nextStepElement.next()
        }
        // If current class contains last_step string system shold show submit button
        current_active = nextStepElement.attr("class")
        if(current_active.includes('last_step') ){
          $("#new-form-submit-plugin-license").show();
          $("#new-form-next-plugin-license").hide();
        } else {
          $("#new-form-submit-plugin-license").hide();
          $("#new-form-next-plugin-license").show();
        }

        var currentClass = nextStepElement.attr("class").split(" ")[0];
        $('.' + currentClass).removeClass('hidden-element');
        $('.' + currentClass).addClass('active');
        $('.' + currentClass).removeAttr('style');

        if (currentClass == "step_03"){
          $("#new_plugin_license").enableClientSideValidations();
        }
      });
    }
  });

  // When user clicks on Back button logic
  $('#new-form-previous-plugin-license').off("click").on("click", function (e) {
    var progress_line = $(".f1-steps").find('.f1-progress-line');
    var stepClasses = $('[class*=step_0]').not('.hidden-element');
    var classNames = [];
    stepClasses.each(function() {
      classNames.push($(this).attr("class"));
    });
    var activeClass = classNames[0].split(" ");
    var current_active_step = $(".f1-steps").find('.f1-step.active');
    $('.' + activeClass.join(".")).fadeOut(400, function() {
      prev_class = current_active_step.removeClass('active').prev()
      if(prev_class.css('display') !== 'none'){
        // check if prev class having display not none
        prev_class.removeClass('activated').addClass('active');
      } else {
        prev_class.prev().removeClass('activated').addClass('active');
      }
      progress_bar(progress_line, 'left');
      $('.' + activeClass[0]).addClass('hidden-element');
      $('.' + activeClass[0]).removeClass('active');
      var prevStepElement  = $('.' + activeClass[0]).prev('[class^="step_"]');
      if(prev_class.css('display') == 'none'){
        prevStepElement = prevStepElement.prev()
      }
      var preClass = prevStepElement.attr("class").split(" ")[0];


      // If current class contains last_step string system shold show submit button
      current_active = prevStepElement.attr("class")
      if(current_active.includes('last_step') ){
        $("#new-form-submit-plugin-license").show();
        $("#new-form-next-plugin-license").hide();
      } else {
        $("#new-form-submit-plugin-license").hide();
        $("#new-form-next-plugin-license").show();
      }

      $('.' + preClass).removeClass('hidden-element');
      $('.' + preClass).removeAttr('style');
      $('.' + preClass).addClass('active');
      if (preClass == "step_01") {
        $("#new-form-previous-plugin-license").hide();
        $("#new-form-next-plugin-license").show();
      }else {
        $("#new-form-previous-plugin-license").show();
        $("#new-form-next-plugin-license").show();
      }
    });
  });

  country_select_init("plugin_license", "#plugin_license_country_code" );

  // Make state field disabled by on trigger the country
  $("#plugin_license_country_code").trigger("change");

  var pl_inputtag = $('#new_plugin_license_sids');
  pl_inputtag.tagsinput({
    maxChars: 12,
    maxTags: 50,
    delimiter: [',', ';'],
    trimValue: true
  });

  $('.tagsinput_acct').attr('placeholder', I18n.t('change_pl_label_sidsno', { max_limit: 50 }) );

  pl_inputtag.focus();
  pl_inputtag.on('itemRemoved', function(event) {
    var tagsArray = pl_inputtag.tagsinput('items');
    if(tagsArray.length == 0){
      $('.tagsinput_acct').attr('placeholder', I18n.t('change_pl_label_sidsno', { max_limit: 50 }) );
    }

    if(tagsArray.length > 0) {
      let invalidTagsArray = tagsArray.filter(isInvalidTag);
      invalidTagsArray.forEach(changeTagColor);
    }
  });

  pl_inputtag.on('itemAdded', function(event) {
    item = event.item;
    if (isInvalidTag(item)) {
      changeTagColor(item)
    }
  });

  $(".bootstrap-tagsinput").on('focusout', function () {
    var tagsArray = pl_inputtag.tagsinput('items');
    if(tagsArray.length > 0) {
      let invalidTagsArray = tagsArray.filter(isInvalidTag);
      invalidTagsArray.forEach(changeTagColor);
    } else {
      $('.tagsinput_acct').attr('placeholder', I18n.t("change_pl_label_sidsno", { max_limit: 50 }) );
    }
  });

  $('.modal').on('hide', function() {
    $('[class*=step_0]').not('.hidden-element').removeClass('active').addClass('hidden-element');
  });
}

plugin_license_edit_init = function () {
  var mode;
  mode = 'edit';
  // Based on size of steps reducing the width of each step navigation div
  // Needs to move with CSS
  $('.f1-step').css({
      'position': 'relative',
      'float': 'left',
      'width': '30%',
      'padding': '0 5px'
  });

  $("#edit-form-next-plugin-license").show();
  $("#edit-form-previous-plugin-license").hide();
  $("#edit-form-submit-plugin-license").hide();
  // When user clicks on Next button logic
  $('#edit-form-next-plugin-license').off("click").on("click", function (e) {
    $('#new_plugin_license').resetClientSideValidations();
    var classNames = [];
    var stepClasses = $('[class*=step_0]').not('.hidden-element');
    stepClasses.each(function() {
      classNames.push($(this).attr("class"));
    });
    var activeClass = classNames[0].split(" ");

    if(validateActiveForm(activeClass[0], mode)){
      var progress_line = $(".f1-steps").find('.f1-progress-line');
      var current_active_step = $(".f1-steps").find('.f1-step.active');
      $('.' + activeClass.join(".")).fadeOut(400, function() {
        next_class = current_active_step.removeClass('active').addClass('activated').next();
        // Check if next parent div having display non class, then move next class and add active
        if(next_class.css('display') !== 'none'){
          // check if next class having display not none
          next_class.addClass('active')
        } else {
          next_class.next().addClass('active')
        }
        progress_bar(progress_line, 'right');
        $("#edit-form-previous-plugin-license").show();
        $('.' + activeClass[0]).addClass('hidden-element');
        $('.' + activeClass[0]).removeClass('active');
        var nextStepElement  = $('.' + activeClass[0]).next('[class^="step_"]');
        if(next_class.css('display') == 'none'){
          nextStepElement = nextStepElement.next()
        }
        // If current class contains last_step string system shold show submit button
        current_active = nextStepElement.attr("class")
        if(current_active.includes('last_step') ){
          $("#edit-form-submit-plugin-license").show();
          $("#edit-form-next-plugin-license").hide();
        } else {
          $("#edit-form-submit-plugin-license").hide();
          $("#edit-form-next-plugin-license").show();
        }

        var currentClass = nextStepElement.attr("class").split(" ")[0];
        $('.' + currentClass).removeClass('hidden-element');
        $('.' + currentClass).addClass('active');
        $('.' + currentClass).removeAttr('style');

        if (currentClass == "step_03"){
          $("#new_plugin_license").enableClientSideValidations();
        }
      });
    }
  });

  // When user clicks on Back button logic
  $('#edit-form-previous-plugin-license').off("click").on("click", function (e) {
    var progress_line = $(".f1-steps").find('.f1-progress-line');
    var stepClasses = $('[class*=step_0]').not('.hidden-element');
    var classNames = [];
    stepClasses.each(function() {
      classNames.push($(this).attr("class"));
    });
    var activeClass = classNames[0].split(" ");
    var current_active_step = $(".f1-steps").find('.f1-step.active');
    $('.' + activeClass.join(".")).fadeOut(400, function() {
      prev_class = current_active_step.removeClass('active').prev()
      if(prev_class.css('display') !== 'none'){
        // check if prev class having display not none
        prev_class.removeClass('activated').addClass('active');
      } else {
        prev_class.prev().removeClass('activated').addClass('active');
      }
      progress_bar(progress_line, 'left');
      $('.' + activeClass[0]).addClass('hidden-element');
      $('.' + activeClass[0]).removeClass('active');
      var prevStepElement  = $('.' + activeClass[0]).prev('[class^="step_"]');
      if(prev_class.css('display') == 'none'){
        prevStepElement = prevStepElement.prev()
      }
      var preClass = prevStepElement.attr("class").split(" ")[0];


      // If current class contains last_step string system shold show submit button
      current_active = prevStepElement.attr("class")
      if(current_active.includes('last_step') ){
        $("#edit-form-submit-plugin-license").show();
        $("#edit-form-next-plugin-license").hide();
      } else {
        $("#edit-form-submit-plugin-license").hide();
        $("#edit-form-next-plugin-license").show();
      }

      $('.' + preClass).removeClass('hidden-element');
      $('.' + preClass).removeAttr('style');
      $('.' + preClass).addClass('active');
      if (preClass == "step_01") {
        $("#edit-form-previous-plugin-license").hide();
        $("#edit-form-next-plugin-license").show();
      }else {
        $("#edit-form-previous-plugin-license").show();
        $("#edit-form-next-plugin-license").show();
      }
    });
  });

  country_select_init("plugin_license", "#plugin_license_country_code" );

  var pl_inputtag = $('#edit_plugin_license_sids');
  pl_inputtag.tagsinput({
    maxChars: 12,
    maxTags: 50,
    delimiter: [',', ';'],
    trimValue: true
  });

  $('.tagsinput_acct').attr('placeholder', '' );

  pl_inputtag.focus();
  pl_inputtag.on('itemRemoved', function(event) {
    var tagsArray = pl_inputtag.tagsinput('items');
    if(tagsArray.length == 0){
      $('.tagsinput_acct').attr('placeholder', I18n.t('change_pl_label_sidsno', { max_limit: 50 }) );
    }

    if(tagsArray.length > 0) {
      let invalidTagsArray = tagsArray.filter(isInvalidTag);
      invalidTagsArray.forEach(changeTagColor);
    }
  });

  pl_inputtag.on('itemAdded', function(event) {
    item = event.item;
    if (isInvalidTag(item)) {
      changeTagColor(item)
    }
  });

  $(".bootstrap-tagsinput").on('focusout', function () {
    var tagsArray = pl_inputtag.tagsinput('items');
    if(tagsArray.length > 0) {
      let invalidTagsArray = tagsArray.filter(isInvalidTag);
      invalidTagsArray.forEach(changeTagColor);
    } else {
      $('.tagsinput_acct').attr('placeholder', I18n.t("change_pl_label_sidsno", { max_limit: 50 }) );
    }
  });

  $('.modal').on('hide', function() {
    $('[class*=step_0]').not('.hidden-element').removeClass('active').addClass('hidden-element');
  });
}

function isInvalidTag(tag) {
  return (tag.length != 12 || isNaN(tag) || /\s/.test(tag));
}

function changeTagColor(tagValue) {
  $(".bootstrap-tagsinput span").filter(function() {
      return $(this).text() === tagValue;
  }).attr('style', 'background-color: red !important; color: white;');
}

function validateActiveForm(current_class, mode) {
  // Validate license fields for step 01
  var hasError = true;

  //Trigger all input and select tags manually
  if(current_class == "step_01"){
    $("." + current_class).find("input, select").each(function () {
      $(this).trigger("blur");
    });
    //Return false if any child div has a class of "has-error"
    $('.step_01').find('div').each(function() {
      if ($(this).hasClass('has-error')) {
        hasError = false;
      }
    });
  } else if(current_class == "step_02") {
    let pl_inputtag_id = mode == 'new' ? '#new_plugin_license_sids' : '#edit_plugin_license_sids';
    let pl_inputtag = $(pl_inputtag_id);
    let tagsArray = pl_inputtag.tagsinput('items');
    if (tagsArray.length == 0) {
      display_sid_acts_error();
      hasError = false;
    } else if (tagsArray.length > 0) {
      let invalidTagsArray = tagsArray.filter(isInvalidTag);
      if (invalidTagsArray.length === 0) {
        remove_sid_acts_error();
        $('.bootstrap-tagsinput').removeClass('bootstrap_tagsinput_error_msg');
      } else {
        display_sid_acts_error();
        hasError = false;
      }
    }
  }
  return hasError;
}

document_ready = function () {
  var crud_options;
  crud_options = {
    'plugin-license' : {
      new: plugin_license_new_init,
      edit: plugin_license_edit_init
    },
  };

  init_datatable(crud_options);
  return init_search();
};

var init_datatable = (crud_options) =>
  dt.initDatatable($("#plugin-licenses-table"), {}, crud_options);

init_search = function () {
  return $(".searchtoggle.plugin_licenses").on(
    "on_search_toggled",
    function () {
      init_advsearch("/plugin_licenses/advfind");
      create_advfind_buttons("plugin_license");
    }
  );
};

const initDownload = () => $('a.plugin-license-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("plugin_license_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("download_failed", {type: I18n.t("plugin_license").toLowerCase()}));
    }
  });
  return false;
});

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

var refresh = () => $.get("/plugin_licenses/download_plugin_license_export.json", function (obj, status, xhr) {
  if (sessionExpired(xhr)) {
    return;
  }
  const { download_path } = obj;
  if (download_path !== undefined) {
    show_alert(
      '<a class="plugin-license-export-download" href="' +
        download_path +
        '">' +
        I18n.t("export_completed", {type: I18n.t("plugin_license").toLowerCase()}) +
        "</a>"
    );
    initDownload();
    return;
  }

  if (download_path === undefined) {
    return setTimeout(refresh, 30000);
  }
});

jQuery(function () {
  return run_ontag("plugin_licenses", function () {
    document_ready();
    if ($('#created').length) {
      setTimeout(refresh, 30000);
    }
  });
});

// ---
// generated by coffee-script 1.9.2
